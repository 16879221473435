import {arrow} from '@tightrope/newtab';
const Util = require("./util.js");
const TOP_DOMAIN = Util.TOP_DOMAIN;

$(document).ready(async function() {
    var searchElement = 'search-text';
    if (searchElement && searchElement !== '') window._tr_ac_se = searchElement;
    var userGuid = await Util.getGuid();
    arrow();
    if (new URL(window.location.href).searchParams.get("tutorial") === "true") {
        window.scrollTo(0, 450);
        $(".firstrun").css("display", "block");
        $("body").css({"overflow":"hidden"});
        $( ".step-a" ).click(function() {
            $( ".step-a" ).fadeOut();
            $( ".step-b" ).show();
            $( ".b-card" ).slideDown();
            $("body").css({"overflow":"scroll"});
        });

        $( ".step-b" ).click(function() {
            $( ".b-card" ).slideUp();
            $( ".firstrun" ).hide();
        });
    }

    var firstrun = await Util.isFirstRun();
    if(firstrun){
        if(document.getElementById("firstRunModal") && document.getElementsByClassName("layers")[0]){
            document.getElementsByClassName("layers")[0].style.display = "block";
            document.getElementById("firstRunModal").style.display = "block";
        }
    }
    else {
        if(document.getElementById("firstRunModal") && document.getElementsByClassName("layers")[0]){
            document.getElementsByClassName("layers")[0].style.display = "none";
            document.getElementById("firstRunModal").style.display = "none";
        }
    }

    $("#search-button").click(function(){
        $("#search-form").submit();
    });

    $("#search-form").submit(e => {
        e.preventDefault();
    window.location = "https://services." + TOP_DOMAIN + "/crx/search.php?action=nt&guid=" + userGuid + "&k=" + $("#search-text").val();
    });

    $("#gotItBtn").click(function () {
        document.getElementById("firstRunModal").style.display = "none";
        document.getElementsByClassName("layers")[0].style.display = "none";
    });

});
