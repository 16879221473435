
// Returns a promise object
function getPromise(data) {
    return $.ajax(data);
}

function checkIfUpdate(ts, minDiff){
    return (!ts || (Date.now() - ts)/1000 > (minDiff * 60))
}

function openUrl(url)
{
    window.open(url, '_blank');
}

const searchParams = new URL(window.location.href).searchParams;
searchParams.forEach((value, param) => setSetting(param, value));

function getSetting(name) {
   return localStorage.getItem(name);
}

function setSetting(name, value) {
    localStorage.setItem(name, value);
}

function sendMessage(msgName, msgObj){
    return sendMessageToExt({task: "sendMessage", msg: msgName, obj: msgObj});
}

function sendMessageToExt(msgToPost){
    return new Promise(function (resolve, reject){
        var receiveMessage = function(e){
            if (e.data  && e.data.msg == "msgResponse"){
                window.removeEventListener("message", receiveMessage, false);
                resolve(e.data.res);
            }
        }
        window.addEventListener("message", receiveMessage, false);
        window.postMessage(msgToPost, "*");
        setTimeout(function() {
            reject('message timed out');
        }, 500);
    });
}

async function getGuid() {
    try{
        return getSetting("guid") || await sendMessageToExt({task: "getSetting", name: "guid"});
    }
    catch (e) {
        return "";
    }
}

async function isFirstRun(){

    try {
        const isFirstRun = await sendMessageToExt({task: "getSetting", name: "isFirstRun"});
        if (isFirstRun !== "false"){
            await sendMessageToExt({task: "setSetting", name: "isFirstRun", value: "false"});
            return true;
        }
        return false;
    }
    catch (e) {
        return false
    }

    var firstRun = getSetting("firstNT") === "true" ||
        (getSetting("firstRdr") && getSetting("firstNT") !== "false");
    if (firstRun) {
        setSetting("firstNT", "false");
    }
    return firstRun;
}


function getTopDomain() {
    if (typeof DOMAIN !== "undefined") return DOMAIN;
    const hostname = window.location.hostname;
    let parts = hostname.split(/\./);
    return parts.length == 1 ? hostname : parts.slice(-2).join(".")
}

const TOP_DOMAIN = getTopDomain();

module.exports = {
    openUrl,
    getPromise,
    getSetting,
    setSetting,
    checkIfUpdate,
    TOP_DOMAIN,
    getGuid,
    isFirstRun
};